import { ChevronDownIcon } from "@chakra-ui/icons";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { useState } from "react";

const SpeakingAndWriting = () => {
  // State Variables
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [expanded5, setExpanded5] = useState(false);
  const [expanded6, setExpanded6] = useState(false);
  const [expanded7, setExpanded7] = useState(false);
  const [expanded8, setExpanded8] = useState(false);

  // State Functions
  const expand1 = () => {
    setExpanded1(!expanded1);
  };
  const expand2 = () => {
    setExpanded2(!expanded2);
  };
  const expand3 = () => {
    setExpanded3(!expanded3);
  };
  const expand4 = () => {
    setExpanded4(!expanded4);
  };
  const expand5 = () => {
    setExpanded5(!expanded5);
  };
  const expand6 = () => {
    setExpanded6(!expanded6);
  };
  const expand7 = () => {
    setExpanded7(!expanded7);
  };
  const expand8 = () => {
    setExpanded8(!expanded8);
  };
  const collapseAll = () => {
    setExpanded1(false);
    setExpanded2(false);
    setExpanded3(false);
    setExpanded4(false);
    setExpanded5(false);
    setExpanded6(false);
    setExpanded7(false);
    setExpanded8(false);
  };
  const expandAll = () => {
    setExpanded1(true);
    setExpanded2(true);
    setExpanded3(true);
    setExpanded4(true);
    setExpanded5(true);
    setExpanded6(true);
    setExpanded7(true);
    setExpanded8(true);
  };
  return (
    <div className="flex flex-col items-center justify-center gap-10 mt-4 mb-[5rem]">
      <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
        <div className="flex justify-between items-center">
          <h1 className="font-bold text-lg">
            Speaking and Writing (54-67 minutes)
          </h1>
          {expanded1 ||
          expanded2 ||
          expanded3 ||
          expanded4 ||
          expanded5 ||
          expanded6 ||
          expanded7 ||
          expanded8 ? (
            <button
              onClick={collapseAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Collapse All <ChevronUpIcon className="scale-150" />
            </button>
          ) : (
            <button
              onClick={expandAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Expand All <ChevronDownIcon className="scale-150" />
            </button>
          )}
        </div>
        <hr className="mt-2" />
        <p className="mt-2">
          This part of test contains seven different question types, and tests
          your speaking and writing skills.
        </p>
        <ul className="transition-all ease-in-out duration-300">
          <li
            className="mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand1}
          >
            <span className="text-[#3182ce]">1. Personal Introduction</span>
            <span
              className={`transform ${
                expanded1 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color="#3182ce" />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded1 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> This question does not
              contribute to your score. It is an opportunity for you to get
              familiar with PTE test technology and to help you prepare for your
              speaking and listening questions.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> You will have 25 seconds to
              read the prompt and prepare your response, and then 30 seconds to
              record your response. You are only able to record your answer
              once.
            </p>
          </div>

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand2}
          >
            <span className="text-[#3182ce]">2. Read aloud</span>
            <span
              className={`transform ${
                expanded2 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded2 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, text will
              appear on the screen and you need to read it aloud.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You have 30-40 seconds to
              read the text and prepare.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> After a short tone, start
              speaking immediately. Do not start speaking before the microphone
              opens because you voice will not be recorded.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> If you remain silent for
              longer than three seconds, the recording will stop.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You are only able to record
              your response once.
            </p>
            <p className="mt-2 font-bold">
              Skills accessed: Reading and Speaking
            </p>
            {/* Youtube Video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/gHDr9n7xt3U?si=c1qehwg5t5yEPBCM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand3}
          >
            <span className="text-[#3182ce]">3. Repeat sentence</span>
            <span
              className={`transform ${
                expanded3 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded3 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type you need
              to repead the sentence after you listen a recording of the
              sentence.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The audio begins to play
              automatically. When the audio finishes, the microphone opens and
              the recording status box shows "Recording".
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Speak into the microphone
              immediately (there is no short tone) and repead exactly what you
              heard.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You are not able to replay
              the audio. You are only able to record your response once.
            </p>
            <p className="mt-2 font-bold">
              Skills assessed: Listening and Speaking
            </p>
            {/* Youtube Video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/S9x0S9J6TEY?si=y4-CHXUeDDIwHM0i"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand4}
          >
            <span className="text-[#3182ce]">4. Describe image</span>
            <span
              className={`transform ${
                expanded4 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded4 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this iten type, an image
              appears on the screen and you need to describe the image in
              detail.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You have 25 seconds to study
              the image and prepare your response.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> After a short tone start
              speaking immediately. Do not start speaking before the microphone
              opens because your voice will not be recorded.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> If you remain silent for
              longer than three seconds, the recording will stop.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You are only able to record
              your response once.
            </p>
            <p className="mt-2">
              <span className="font-bold">Skills assessed: Speaking</span>
            </p>
            {/* YouTube video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/GUi5k3SaJgI?si=n-MjRqJoaKuSYP9x"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand5}
          >
            <span className="text-[#3182ce]">5. Re-tell lecture</span>
            <span
              className={`transform ${
                expanded5 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded5 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you need
              to re-tell the lecture in your own words after listening to or
              watching a lecture.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The audio begins to play
              automatically. You may also see an image related to the audio.
              After the audio finishes, you have 10 seconds to prepare.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> After a short tone, start
              speaking immediately. Do not start speaking before the microphone
              opens because your voice will not be recorded.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> While the audio is playing,
              you can take notes on the erasable noteboard provided.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You are only able to record
              your response once.
            </p>
            <p className="mt-2">
              <span className="font-bold">
                Skills assessed: Listening and Speaking
              </span>
            </p>
            {/* YouTube video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/nxq4_zPlF8k?si=uV6hjoYJ1mTpe-EH"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand6}
          >
            <span className="text-[#3182ce]">6. Answer short question</span>
            <span
              className={`transform ${
                expanded6 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded6 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type you need
              to listening to a question and answer with a single word or a few
              words.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The audio begins to play
              automatically. You may also see an image.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Speak into the microphone
              immediately (there is no short tone) and answer the question with
              one or a few words.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> If you remain silent for
              longer than three seconds, the recording will stop.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You are not able to replay
              the audio. You are only able to record your response once.
            </p>
            <p className="mt-2">
              <span className="font-bold">
                Skills assessed: Listening and Speaking
              </span>
            </p>
            {/* Youtube Video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/_Q_UwtcFhDY?si=SRWNQsI6yANxIEL6"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand7}
          >
            <span className="text-[#3182ce]">7. Summarize written text</span>
            <span
              className={`transform ${
                expanded7 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded7 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, write a
              one-sentence summary of the passage after reading the text.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You have 10 minutes to write
              your summary.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Make sure to include the main
              points of the reading passage in a full, single sentence of no
              more than 75 words.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You will need to use complex
              or compound sentence to be able to summarize the main point of the
              passage and also briefly mention the supporting details.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> There are also cut, copy and
              paste buttons which you may use while constructing your summary.
            </p>
            <p className="mt-2">
              <span className="font-bold">
                Skills assessed: Reading and Writing
              </span>
            </p>
            {/* Youtube video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/IQdI-uNTScU?si=V5cPLcYt6EAWUNwW"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand8}
          >
            <span className="text-[#3182ce]">8. Essay</span>
            <span
              className={`transform ${
                expanded8 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>

          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded8 ? "max-h-screen" : ""
            }`}
          >
            <hr />
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type you need
              to write a 200-300 word essay on a given topic.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You have 20 minutes to write
              your essay.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Make sure to write a minimum
              of 200 words, but no more than 300 words. The Word Count at the
              bottom of the screen counts the number of words you write.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> There are also cut, copy and
              paste buttons, which you may choose to use while constructing your
              response.
            </p>
            <p className="mt-2">
              <span className="font-bold">Skills assessed: Writing</span>
            </p>
            {/* YouTube video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/N17a1HmBH9o?si=M0PCXRj08ZG9VG0H"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </ul>
      </section>
    </div>
  );
};

export default SpeakingAndWriting;
