import Listening from "./Listening";
import Speaking from "./Speaking";
import Reading from "./Reading";
import Writing from "./Writing";

const Tofel = () => {
  return (
    <div>
      <Listening />
      <Speaking />
      <Reading />
      <Writing />
    </div>
  );
};

export default Tofel;
