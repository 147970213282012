import { Link } from "react-router-dom";

const Speaking = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-10 mt-4 mb-[5rem]">
      <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
        <h1 className="text-lg font-bold">Speaking (17 minutes)</h1>
        <hr className="mt-1" />
        <p className="mt-2">
          There are four questions/tasks that resemble real-life situations you
          might encounter both in and outside of a classroom.
        </p>
        <p className="mt-2">
          You'll get 15-30 seconds of preparation time before each response, and
          your response will be 45 or 60 seconds long.
        </p>
        <p className="mt-2">
          To respond, you'll speak into the microphone and your responses will
          be recorded.
        </p>
        <p className="mt-2">
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/speaking-independent-question-1.html"
            target="_blank"
            className=" text-[#1267E9]"
          >
            Question 1: Independent Speaking
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/speaking-integrated-question-2.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Question 2: Integrated Speaking
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/speaking-integrated-questions-3-4.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Questions 3 and 4: Integrated Speaking
          </Link>
        </p>
      </section>
    </div>
  );
};

export default Speaking;
