import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useState } from "react";

const Reading = () => {
  // State Variables
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [expanded5, setExpanded5] = useState(false);
  const [expanded6, setExpanded6] = useState(false);
  const [expanded7, setExpanded7] = useState(false);
  const [expanded8, setExpanded8] = useState(false);
  const [expanded9, setExpanded9] = useState(false);
  const [expanded10, setExpanded10] = useState(false);
  const [expanded11, setExpanded11] = useState(false);

  // State Functions
  const expand1 = () => {
    setExpanded1(!expanded1);
  };
  const expand2 = () => {
    setExpanded2(!expanded2);
  };
  const expand3 = () => {
    setExpanded3(!expanded3);
  };
  const expand4 = () => {
    setExpanded4(!expanded4);
  };
  const expand5 = () => {
    setExpanded5(!expanded5);
  };
  const expand6 = () => {
    setExpanded6(!expanded6);
  };
  const expand7 = () => {
    setExpanded7(!expanded7);
  };
  const expand8 = () => {
    setExpanded8(!expanded8);
  };
  const expand9 = () => {
    setExpanded9(!expanded9);
  };
  const expand10 = () => {
    setExpanded10(!expanded10);
  };
  const expand11 = () => {
    setExpanded11(!expanded11);
  };
  const collapseAll = () => {
    setExpanded1(false);
    setExpanded2(false);
    setExpanded3(false);
    setExpanded4(false);
    setExpanded5(false);
    setExpanded6(false);
    setExpanded7(false);
    setExpanded8(false);
    setExpanded9(false);
    setExpanded10(false);
    setExpanded11(false);
  };
  const expandAll = () => {
    setExpanded1(true);
    setExpanded2(true);
    setExpanded3(true);
    setExpanded4(true);
    setExpanded5(true);
    setExpanded6(true);
    setExpanded7(true);
    setExpanded8(true);
    setExpanded9(true);
    setExpanded10(true);
    setExpanded11(true);
  };
  return (
    <div className="flex flex-col items-center justify-center gap-10 mt-4 mb-[5rem]">
      <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
        <div className="flex justify-between items-center">
          <h1 className="mb-2 font-bold text-lg">Reading (60 minutes)</h1>
          {expanded1 ||
          expanded2 ||
          expanded3 ||
          expanded4 ||
          expanded5 ||
          expanded6 ||
          expanded7 ||
          expanded8 ||
          expanded9 ||
          expanded10 ||
          expanded11 ? (
            <button
              onClick={collapseAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Collapse All <ChevronUpIcon className="scale-150" />
            </button>
          ) : (
            <button
              onClick={expandAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Expand All <ChevronDownIcon className="scale-150" />
            </button>
          )}
        </div>
        <hr />
        <p className="mt-2">
          The Reading section consists of 40 questions, designed to test a wide
          range of reading skills. These include reading for gist, reading for
          main ideas, reading for detail, skimming, understanding logical
          argument and recognising writers' opinions, attitudes and purpose.
        </p>
        <p className="mt-2">
          <span className="font-semibold">IELTS Academic test: </span>
          This includes three long texts which range from the descriptive and
          factual to the discursive and analytical. These are taken from books,
          journals, magazines and newspapers. They have been selected for a
          non-specialist audience but are appropriate for people entering
          university courses or seeking professional registration.
        </p>
        <p className="mt-2">
          <span className="font-semibold">IELTS General Training test: </span>
          This includes extracts from books, magazines, newspapers, notices,
          advertisements, company handbooks and guidelines. These are materials
          you are likely to encounter on a daily basis in an English-speaking
          environment.
        </p>
        <p className="mt-2">
          A variety of question types are used, chosen from the following:
        </p>
        <ul className="transition-all ease-in-out duration-300">
          {/* 1. Multiple choice */}
          <li
            className="mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand1}
          >
            <span className="text-[#3182ce]">1. Multiple choice</span>
            <span
              className={`transform ${
                expanded1 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color="#3182ce" />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded1 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are required to
              choose the best answer from four alternatives (A, B, C or D), or
              the best two answers from five alternatives (A, B, C, D or E), or
              the best three answers from seven alternatives (A, B, C, D, E, F
              or G).
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> The questions may involve
              completing a sentence, where they are given the first part of a
              sentence and then choose the best way to complete it from the
              options, or could involve complete questions; with the test takers
              choosing the option which best answers them.
            </p>
          </div>

          {/* 2. Identifying information */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand2}
          >
            <span className="text-[#3182ce]">2. Identifying information</span>
            <span
              className={`transform ${
                expanded2 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded2 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers will be given a
              number of statements and asked: 'Do the following statements agree
              with the information in the text?' They are then required to write
              'true', 'false' or 'not given' in the boxes on their answer
              sheets.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> It is important to understand
              the difference between 'false' and 'not given'. 'False' means that
              the passage states the opposite of the statement in question; 'not
              given' means that the statement is neither confirmed nor
              contradicted by the information in the passage.
            </p>
          </div>

          {/* 3. Identifying writer's views/claims */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand3}
          >
            <span className="text-[#3182ce]">
              3. Identifying writer's views/claims
            </span>
            <span
              className={`transform ${
                expanded3 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded3 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers will be given a
              number of statements and asked: 'Do the following statements agree
              with the views/claims of the writer?'' They are required to write
              'yes', 'no' or 'not given in the boxes on their answer sheet.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> It is important to understand
              the difference between 'no' and 'not given'. 'No' means that the
              views or claims of the writer explicitly disagree with the
              statement; 'not given' means that the view or claim is neither
              confirmed nor contradicted.
            </p>
          </div>

          {/* 4. Matching information */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand4}
          >
            <span className="text-[#3182ce]">4. Matching information</span>
            <span
              className={`transform ${
                expanded4 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded4 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are required to
              locate specific information within the lettered
              paragraphs/sections of a text, and to write the letters of the
              correct paragraphs/sections in the boxes on their answer sheet.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> They may be asked to find:
              specific details, an example, a reason, a description, a
              comparison, a summary, an explanation.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> They will not necessarily
              need to find information in every paragraph/section of the text,
              but there may be more than one piece of information that test
              takers need to locate in a given paragraph/section. When this is
              the case, they will be told that they can use any letter more than
              once.
            </p>
          </div>

          {/* 5. Matching headings */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand5}
          >
            <span className="text-[#3182ce]">5. Matching headings</span>
            <span
              className={`transform ${
                expanded5 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded5 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are given a list
              of headings, usually identified with lower-case Roman numerals (i,
              ii, iii, etc,). A heading will refer to the main idea of the
              paragraph or section of the text. Test takers must match the
              heading to the correct paragraphs or sections, which are marked
              alphabetically. Test takers write the appropriate Roman numerals
              in the boxes on their answer sheets.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> There will always be more
              headings than there are paragraphs or sections, so that some
              headings will not be used. It is also possible that some
              paragraphs or sections may not be included in the task. One or
              more paragraphs or sections may already be matched with a heading
              as an example for test takers.
            </p>
          </div>

          {/* 6. Matching features */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand6}
          >
            <span className="text-[#3182ce]">6. Matching features</span>
            <span
              className={`transform ${
                expanded6 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded6 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are required to
              match a set of statements or pieces of information to a list of
              options. The options are a group of features from the text, and
              are identified by letters.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> Test takers may, for example,
              be required to match different research findings to a list of
              researchers, or characteristics to age groups, events to
              historical periods, etc. It is possible that some options will not
              be used, and that others may be used more than once. The
              instructions will inform test takers if options may be used more
              than once.
            </p>
          </div>

          {/* 7. Matching sentence endings */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand7}
          >
            <span className="text-[#3182ce]">7. Matching sentence endings</span>
            <span
              className={`transform ${
                expanded7 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded7 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are given the
              first half of a sentence based on the text and asked to choose the
              best way to complete it from a list of possible options. They will
              have more options to choose from than there are questions.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> The questions are in the same
              order as the information in the passage: that is, the answer to
              the first question in this group will be found before the answer
              to the second question, and so on.
            </p>
          </div>

          {/* 8. Sentence completion */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand8}
          >
            <span className="text-[#3182ce]">8. Sentence completion</span>
            <span
              className={`transform ${
                expanded8 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>

          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded8 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers complete
              sentences in a given number of words taken from the text. They
              must write their answers on the answer sheet.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> If test takers write more
              than the number of words asked for, they will lose the mark.
              Numbers can be written using figures or words.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> The questions are in the same
              order as the information in the passage: that is, the answer to
              the first question in this group will be found before the answer
              to the second question, and so on.
            </p>
          </div>

          {/* 9. Summary, note, table, flow-chart completion */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand9}
          >
            <span className="text-[#3182ce]">
              9. Summary, note, table, flow-chart completion
            </span>
            <span
              className={`transform ${
                expanded9 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>

          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded9 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are given a
              summary of a section of the text, and are required to complete it
              with information drawn from the text. The summary will usually be
              of only one part of the passage rather than the whole.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The given information may be
              in the form of: several connected sentences of text (referred to
              as a summary), several notes (referred to as notes), a table with
              some of its cells empty or partially empty (referred to as a
              table), a series of boxes or steps linked by arrows to show a
              sequence of events, with some of the boxes or steps empty or
              partially empty (referred to as a flow-chart).
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The answers will not
              necessarily occur in the same order as in the text. However, they
              will usually come from one section rather than the entire text.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> There are two variations of
              this task type. Test takers may be asked either to select words
              from the text or to select from a list of answers.
            </p>
          </div>

          {/* 10. Diagram label completion */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand10}
          >
            <span className="text-[#3182ce]">10. Diagram label completion</span>
            <span
              className={`transform ${
                expanded10 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>

          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded10 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are required to
              complete labels on a diagram, which relates to a description
              contained in the text.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> If test takers write more
              than the number of words asked for, they will lose the mark.
              Numbers can be written using figures or words.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> The answers do not
              necessarily occur in order in the passage. However, they will
              usually come from one section rather than the entire text.
            </p>
          </div>

          {/* 11. Short-answer questions */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand11}
          >
            <span className="text-[#3182ce]">11. Short-answer questions</span>
            <span
              className={`transform ${
                expanded11 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>

          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded11 ? "max-h-screen" : ""
            }`}
          >
            <hr />
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers answer questions,
              which usually relate to factual information about details in the
              text. This is most likely to be used with a text that contains a
              lot of factual information and detail.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers must write their
              answers in words or numbers on the answer sheet. Test takers must
              write their answers using words from the text.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> If test takers write more
              than the number of words asked for, they will lose the mark.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> Numbers can be written using
              figures or words. Contracted words are not tested. Hyphenated
              words count as single words. The questions are in the same order
              as the information in the text.
            </p>
          </div>
        </ul>
      </section>
    </div>
  );
};

export default Reading;
