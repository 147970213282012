import { useState } from "react";
import { Image, Box, Flex, Link, IconButton } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Box as="nav" bg="blue.500" color="white" py={2} px={8}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        maxW="1080px"
        mx="auto"
      >
        <Link
          href="https://destinationaus.com/"
          _hover={""}
          className="flex items-center justify-center gap-4"
        >
          <Image
            src="https://firebasestorage.googleapis.com/v0/b/journeytoaustralia-b21d4.appspot.com/o/appIcon_transparent.png?alt=media&token=c1ad3e10-cc90-4944-a4bd-fcaabdca42b4&_gl=1*hgrbtt*_ga*ODM1MzIwMzkuMTY5ODk2MjE2Mg..*_ga_CW55HF8NVT*MTY5ODk2MjE2Mi41LjEuMTY5ODk2MjMzNy41Ni4wLjA"
            alt="Destination Australia"
            className="sm:w-[4rem] w-[6rem]"
          />
          <div className="nav-title hidden sm:flex flex-col">
            <h1 className="text-xl">Destination Australia</h1>
            <p className="text-xs">Your Friend for Australian Dreams</p>
          </div>
        </Link>

        <Flex display={{ base: "none", md: "flex" }} className="gap-[2rem]">
          <Link
            href="https://destinationaus.com/"
            _hover={{ color: "#0cb4da" }}
          >
            Home
          </Link>
          <Link
            href="https://destinationaus.com/tools/"
            color="orange"
            _hover={{ color: "#0cb4da" }}
          >
            Tools
          </Link>
          <Link
            href="https://destinationaus.com/blog/"
            _hover={{ color: "#0cb4da" }}
          >
            Blog
          </Link>
          <Link
            href="https://destinationaus.com/contact-us/"
            _hover={{ color: "#0cb4da" }}
          >
            Contact Us
          </Link>
          {/* <Menu>
            <MenuButton
              as={Button}
              variant="outline"
              ml={4}
              _hover={{ color: "teal.500" }}
            >
              More
            </MenuButton>
            <MenuList>
              <MenuItem
                _hover={{ backgroundColor: "teal.500", color: "white" }}
              >
                Services
              </MenuItem>
              <MenuItem
                _hover={{ backgroundColor: "teal.500", color: "white" }}
              >
                Contact
              </MenuItem>
            </MenuList>
          </Menu> */}
        </Flex>

        <IconButton
          colorScheme={"none"}
          display={{ base: "block", md: "none" }}
          aria-label={menuOpen ? "Close Menu" : "Open Menu"}
          icon={
            menuOpen ? <CloseIcon /> : <HamburgerIcon className="scale-150" />
          }
          onClick={toggleMenu}
          transition="transform 0.3s"
          transform={menuOpen ? "rotate(90deg)" : "rotate(0deg)"}
        />
      </Flex>

      <Box
        display={{ base: "block", md: "none" }}
        bg="blue.500"
        transition="max-height 0.3s"
        overflow="hidden"
        maxHeight={menuOpen ? "250px" : "0"}
      >
        <Link
          href="https://destinationaus.com/"
          py={3}
          display="block"
          _hover={{ backgroundColor: "#3d8ddf" }}
          className="text-right pr-10 rounded-md"
        >
          Home
        </Link>
        <Link
          href="https://destinationaus.com/tools/"
          py={3}
          display="block"
          color={"orange"}
          _hover={{ backgroundColor: "#3d8ddf" }}
          className="text-right pr-10 rounded-md"
        >
          Tools
        </Link>
        <Link
          href="https://destinationaus.com/blog/"
          py={3}
          mt={2}
          display="block"
          _hover={{ backgroundColor: "#3d8ddf" }}
          className="text-right pr-10 rounded-md"
        >
          Blog
        </Link>
        <Link
          href="https://destinationaus.com/contact-us/"
          py={3}
          mt={2}
          display="block"
          _hover={{ backgroundColor: "#3d8ddf" }}
          className="text-right pr-10 rounded-md"
        >
          Contact Us
        </Link>
        {/* <Menu>
          <MenuButton
            as={Button}
            variant="outline"
            py={2}
            display="block"
            _hover={{ color: "teal.500" }}
          >
            More
          </MenuButton>
          <MenuList>
            <MenuItem
              py={2}
              _hover={{ backgroundColor: "teal.500", color: "white" }}
            >
              Services
            </MenuItem>
            <MenuItem
              py={2}
              _hover={{ backgroundColor: "teal.500", color: "white" }}
            >
              Contact
            </MenuItem>
          </MenuList>
        </Menu> */}
      </Box>
    </Box>
  );
}

export default Navbar;
