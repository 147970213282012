import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useState } from "react";

const Listening = () => {
  // State Variables
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [expanded5, setExpanded5] = useState(false);
  const [expanded6, setExpanded6] = useState(false);

  // State Functions
  const expand1 = () => {
    setExpanded1(!expanded1);
  };
  const expand2 = () => {
    setExpanded2(!expanded2);
  };
  const expand3 = () => {
    setExpanded3(!expanded3);
  };
  const expand4 = () => {
    setExpanded4(!expanded4);
  };
  const expand5 = () => {
    setExpanded5(!expanded5);
  };
  const expand6 = () => {
    setExpanded6(!expanded6);
  };
  const collapseAll = () => {
    setExpanded1(false);
    setExpanded2(false);
    setExpanded3(false);
    setExpanded4(false);
    setExpanded5(false);
    setExpanded6(false);
  };
  const expandAll = () => {
    setExpanded1(true);
    setExpanded2(true);
    setExpanded3(true);
    setExpanded4(true);
    setExpanded5(true);
    setExpanded6(true);
  };
  return (
    <div className="flex flex-col items-center justify-center gap-10 mt-4 mb-[5rem]">
      <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
        <div className="flex justify-between items-center">
          <h1 className="mb-2 font-bold text-lg">Listening (30 minutes)</h1>
          {expanded1 ||
          expanded2 ||
          expanded3 ||
          expanded4 ||
          expanded5 ||
          expanded6 ? (
            <button
              onClick={collapseAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Collapse All <ChevronUpIcon className="scale-150" />
            </button>
          ) : (
            <button
              onClick={expandAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Expand All <ChevronDownIcon className="scale-150" />
            </button>
          )}
        </div>
        <hr />
        <div className="mt-2">
          You will listen to four recordings of native English speakers and then
          write your answers to a series of questions.
          <p className="mt-1">
            <span className="font-semibold">Recording 1: </span>A conversation
            between two people set in an everyday social context.
          </p>
          <p className="mt-1">
            <span className="font-semibold">Recording 2: </span>A monologue set
            in an everyday social context, e.g. a speech about local facilities.
          </p>
          <p className="mt-1">
            <span className="font-semibold">Recording 3: </span>A conversation
            between up to four people set in an educational or training context,
            e.g. a university tutor and a student discussing an assignment.
          </p>
          <p className="mt-1">
            <span className="font-semibold">Recording 4: </span>A monologue on
            an academic subject, e.g. a university lecture.
          </p>
          <p className="mt-2">
            Each recording is associated with 10 questions. The questions are
            designed so that the answers appear in the order they are heard in
            the audio.
          </p>
          <p className="mt-2">
            Test takers write their answers on the question paper as they listen
            and at the end of the test are given 10 minutes to transfer their
            answers to an answer sheet.
          </p>
          <p className="mt-2">
            Following are various question types for listening section:
          </p>
        </div>

        <ul className="transition-all ease-in-out duration-300">
          {/* 1. Multiple choice */}

          <li
            className="mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand1}
          >
            <span className="text-[#3182ce]">1. Multiple choice</span>
            <span
              className={`transform ${
                expanded1 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color="#3182ce" />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded1 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> There is a question followed
              by three possible answers, or the beginning of a sentence followed
              by three possible ways to complete the sentence. Test takers are
              required to choose the one correct answer - A, B or C.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Sometimes, test takers are
              given a longer list of possible answers and told that they have to
              choose more than one. In this case, they should read the question
              carefully to check how many answers are required.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> You have 10 minutes to listen
              and write your summary.
            </p>
          </div>

          {/* 2. Matching */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand2}
          >
            <span className="text-[#3182ce]">2. Matching</span>
            <span
              className={`transform ${
                expanded2 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded2 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are required to
              match a numbered list of items from the listening text to a set of
              options on the question paper.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> The set of options may be
              criteria of some kind.
            </p>
          </div>

          {/* 3. Plan, map, diagram labelling */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand3}
          >
            <span className="text-[#3182ce]">
              3. Plan, map, diagram labelling
            </span>
            <span
              className={`transform ${
                expanded3 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded3 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are required to
              complete labels on a plan (eg of a building), map (eg of part of a
              town) or diagram (e.g. of a piece of equipment).
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> The answers are usually
              selected from a list on the question paper.
            </p>
          </div>

          {/* 4. Form, note, table, flow-chart, summary completion */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand4}
          >
            <span className="text-[#3182ce]">
              4. Form, note, table, flow-chart, summary completion
            </span>
            <span
              className={`transform ${
                expanded4 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded4 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are required to
              fill in the gaps in an outline of part or of all of the listening
              text.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> It may be a form, a set of
              notes, a table, or a flow-chart.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers may have to
              select their answers from a list on the question paper or identify
              the missing words from the recording, keeping to the word limit
              stated in the instructions. Test takers do not have to change the
              words from the recording in any way.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers should read the
              instructions very carefully as the number of words or numbers they
              should use to fill the gaps will vary.
            </p>
            <p className="mt-2 mb-8">
              A word limit is given, and test takers are penalised for writing
              more than the stated number of words, and test takers should check
              this word limit carefully for each task.
            </p>
          </div>

          {/* 5. Sentence completion */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand5}
          >
            <span className="text-[#3182ce]">5. Sentence completion</span>
            <span
              className={`transform ${
                expanded5 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded5 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are required to
              read a set of sentences summarising key information from all the
              listening text or from one part of it. They then fill a gap in
              each sentence using information from the listening text.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> A word limit is given, and
              test takers are penalised for writing more than the stated number
              of words, and test takers should check this word limit carefully
              for each task.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> There are several options but
              only one is correct.
            </p>
          </div>

          {/* 6. Short-answer questions */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand6}
          >
            <span className="text-[#3182ce]">6. Short-answer questions</span>
            <span
              className={`transform ${
                expanded6 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded6 ? "max-h-screen" : ""
            }`}
          >
            <hr />
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are required to
              read a question and then write a short answer using information
              from the listening text.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> A word limit is given, and
              test takers are penalised for writing more than the stated number
              of words, and test takers should check this word limit carefully
              for each task.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> Sometimes test takers are
              given a question which asks them to list two or three points.
            </p>
          </div>
        </ul>
      </section>
    </div>
  );
};

export default Listening;
