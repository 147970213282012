import { Link } from "react-router-dom";

const Writing = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-10 mt-4 mb-[5rem]">
      <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
        <h1 className="text-lg font-bold">Writing (50 minutes)</h1>
        <hr className="mt-1" />
        <p className="mt-2">
          There are two writing tasks that measures your ability to write in
          English in an academic setting, and to present your ideas in a clear,
          well-organized way.
        </p>
        <p className="mt-2">
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/writing-integrated.html"
            target="_blank"
            className=" text-[#1267E9]"
          >
            Question 1: Integrated Writing
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/writing-independent.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Question 2: Independent Writing
          </Link>
        </p>
      </section>
    </div>
  );
};

export default Writing;
