import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import Subclass189 from "./pages/PRPoints/Subclass189";
// import Subclass190 from "./pages/PRPoints/Subclass190";
// import Subclass489 from "./pages/PRPoints/Subclass489";
// import Subclass491 from "./pages/PRPoints/Subclass491";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  // {
  //   path: "/subclass189",
  //   element: <Subclass189 />,
  // },
  // {
  //   path: "/subclass190",
  //   element: <Subclass190 />,
  // },
  // {
  //   path: "/subclass489",
  //   element: <Subclass489 />,
  // },
  // {
  //   path: "/subclass491",
  //   element: <Subclass491 />,
  // },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <App />
    </RouterProvider>
  </React.StrictMode>
);
