import SpeakingAndWriting from "./SpeakingAndWriting";
import Reading from "./Reading";
import Listening from "./Listening";

const Pte = () => {
  return (
    <>
      <h1 className="text-2xl text-center">PTE Academic</h1>
      <SpeakingAndWriting />
      <Reading />
      <Listening />
    </>
  );
};

export default Pte;
