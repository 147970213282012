import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useState } from "react";

const Listening = () => {
  // State Variables
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [expanded5, setExpanded5] = useState(false);
  const [expanded6, setExpanded6] = useState(false);
  const [expanded7, setExpanded7] = useState(false);
  const [expanded8, setExpanded8] = useState(false);

  // State Functions
  const expand1 = () => {
    setExpanded1(!expanded1);
  };
  const expand2 = () => {
    setExpanded2(!expanded2);
  };
  const expand3 = () => {
    setExpanded3(!expanded3);
  };
  const expand4 = () => {
    setExpanded4(!expanded4);
  };
  const expand5 = () => {
    setExpanded5(!expanded5);
  };
  const expand6 = () => {
    setExpanded6(!expanded6);
  };
  const expand7 = () => {
    setExpanded7(!expanded7);
  };
  const expand8 = () => {
    setExpanded8(!expanded8);
  };
  const collapseAll = () => {
    setExpanded1(false);
    setExpanded2(false);
    setExpanded3(false);
    setExpanded4(false);
    setExpanded5(false);
    setExpanded6(false);
    setExpanded7(false);
    setExpanded8(false);
  };
  const expandAll = () => {
    setExpanded1(true);
    setExpanded2(true);
    setExpanded3(true);
    setExpanded4(true);
    setExpanded5(true);
    setExpanded6(true);
    setExpanded7(true);
    setExpanded8(true);
  };
  return (
    <div className="flex flex-col items-center justify-center gap-10 mt-4 mb-[5rem]">
      <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
        <div className="flex justify-between items-center">
          <h1 className="mb-2 font-bold text-lg">Listening (30-43 minutes)</h1>
          {expanded1 || expanded2 || expanded3 || expanded4 || expanded5 ? (
            <button
              onClick={collapseAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Collapse All <ChevronUpIcon className="scale-150" />
            </button>
          ) : (
            <button
              onClick={expandAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Expand All <ChevronDownIcon className="scale-150" />
            </button>
          )}
        </div>
        <hr />
        <p className="mt-2">
          This part of test contains eight different question types, and tests
          your listening skills.
        </p>
        <ul className="transition-all ease-in-out duration-300">
          {/* 1. Summarize spoken text */}

          <li
            className="mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand1}
          >
            <span className="text-[#3182ce]">1. Summarize spoken text</span>
            <span
              className={`transform ${
                expanded1 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color="#3182ce" />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded1 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you will
              hear an audio recording and will need to write a 50-70 word
              summary of what you heard.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The Word Count at the bottom
              of the screen counts the number of words you write. Make sure to
              write a minimum of 50 words, but no more than 70 words.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You have 10 minutes to listen
              and write your summary.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The audio begins to play
              automatically. You are only able to listen to the audio recording
              once.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> There are also cut, copy and
              paste buttons, which you may choose to use while constructing your
              summary.
            </p>
            <p className="mt-2">
              <span className="font-bold">
                Skills assessed: Listening and Writing
              </span>
            </p>
            {/* Youtube Video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/rnwnkj1y-CY?si=uppGZryr34Yi4eTA"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>

          {/* 2. Multiple choice: Multiple answers */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand2}
          >
            <span className="text-[#3182ce]">
              2. Multiple choice: Multiple answers
            </span>
            <span
              className={`transform ${
                expanded2 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded2 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you will
              listen to the recording and answer the multiple-choice question.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The audio begins to play
              automatically. You are only able to listen to the audio recording
              once.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You need to select all of the
              response options that you think are correct from the list of
              possible options.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The options you select are
              highlighted in yellow.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You are only able to record
              your response once.
            </p>
            <p className="mt-2 font-bold">Skills accessed: Listening</p>
            {/* Youtube Video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/zjHQJJ42kao?si=wC2r_xtkjO7EVXtq"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>

          {/* 3. Fill in the Blanks */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand3}
          >
            <span className="text-[#3182ce]">3. Fill in the Blanks</span>
            <span
              className={`transform ${
                expanded3 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded3 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you are
              presented with a transcript of the audio recording; however, some
              words are missing. Restore the transcript by typing in the missing
              words.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The audio plays
              automatically. You are only able to listen to the audio recording
              once.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Click on each gap and type in
              the missing word. You can also use the Tab button on the keyboard
              to move between gaps.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> While the audio is playing,
              you can take notes on the erasable noteboard provided and then
              fill in the gaps.
            </p>
            <p className="mt-2 font-bold">
              Skils assessed: Listening and Writing
            </p>
            {/* Youtube Video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/FITSjRwfHWg?si=WUktUzRPZEYjcKpT"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>

          {/* 4. Highlight correct summary */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand4}
          >
            <span className="text-[#3182ce]">4. Highlight correct summary</span>
            <span
              className={`transform ${
                expanded4 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded4 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you need
              to select the summary that best matches the recording.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The audio begins to play
              automatically. You are only able to listen to the audio recording
              once.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> There are several options but
              only one is correct.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The option you select is
              highlighted in yellow.
            </p>
            <p className="mt-2">
              <span className="font-bold">
                Skils assessed: Listening and Reading
              </span>
            </p>
            {/* YouTube video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/T5JX6V5qPrI?si=m3SYuDe0fAwlYM4v"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>

          {/* 5. Multiple choice: Single answer */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand5}
          >
            <span className="text-[#3182ce]">
              5. Multiple choice: Single answer
            </span>
            <span
              className={`transform ${
                expanded5 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded5 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you need
              to listen to the recording and answer the multiple-choice
              question.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The audio begins to play
              automatically. You are only able to listen to the audio recording
              once.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> There are several options but
              only one is correct.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The option you select is
              highlighted in yellow.
            </p>
            <p className="mt-2">
              <span className="font-bold">Skils assessed: Listening</span>
            </p>
            {/* YouTube video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/CFcRw1Pg_1g?si=e_eJtbv73_viXW4F"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>

          {/* 6. Select missing word */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand6}
          >
            <span className="text-[#3182ce]">6. Select missing word</span>
            <span
              className={`transform ${
                expanded6 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded6 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, the last
              word or group of words in the recording has been replaced by a
              beep sound. Select the most appropriate option to complete the
              recording.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The audio begins to play
              automatically. You are only able to listen to the audio recording
              once.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> There are several options but
              only one is correct.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The option you select is
              highlighted in yellow.
            </p>
            <p className="mt-2">
              <span className="font-bold">Skils assessed: Listening</span>
            </p>
            {/* Youtube Video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/irHRxX-fyu8?si=0kD0eQE7RvnnH3aD"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          {/* 7. Highlight incorrect words */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand7}
          >
            <span className="text-[#3182ce]">7. Highlight incorrect words</span>
            <span
              className={`transform ${
                expanded7 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded7 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you are
              presented with a transcript of the audio recording; however, the
              transcript contains some errors. While listening and reading, you
              need to select the words in the text that differ from those that
              the speaker says.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The audio begins to plays
              automatically. You are only able to listen to the audio recording
              once.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The words you select are
              highlighted in yellow.
            </p>
            <p className="mt-2">
              <span className="font-bold">
                Skils assessed: Listening and Reading
              </span>
            </p>
            {/* Youtube video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/cYfaVAfKdhw?si=EsKkatNORVVgeXci"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>

          {/* 8. Write from dictation */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand8}
          >
            <span className="text-[#3182ce]">8. Write from dictation</span>
            <span
              className={`transform ${
                expanded8 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>

          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded8 ? "max-h-screen" : ""
            }`}
          >
            <hr />
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you hear
              a short sentence. Type the sentence into the response box at the
              bottom of the screen.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The audio begins to play
              automatically. You are only able to listen to the audio recording
              once.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> While the audio is playing,
              you can take notes on the erasable noteboard provided.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Don't forget to check your
              spelling.
            </p>
            <p className="mt-2">
              <span className="font-bold">
                Skils assessed: Listening and Writing
              </span>
            </p>
            {/* YouTube video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/TDSH3GIja4k?si=mWkmOXQk9-lAnQCD"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </ul>
      </section>
    </div>
  );
};

export default Listening;
