import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useState } from "react";

const Writing = () => {
  // State Variables
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);

  // State Functions
  const expand1 = () => {
    setExpanded1(!expanded1);
  };
  const expand2 = () => {
    setExpanded2(!expanded2);
  };
  const expand3 = () => {
    setExpanded3(!expanded3);
  };
  const expand4 = () => {
    setExpanded4(!expanded4);
  };
  const collapseAll = () => {
    setExpanded1(false);
    setExpanded2(false);
    setExpanded3(false);
    setExpanded4(false);
  };
  const expandAll = () => {
    setExpanded1(true);
    setExpanded2(true);
    setExpanded3(true);
    setExpanded4(true);
  };
  return (
    <div className="flex flex-col items-center justify-center gap-10 mt-4 mb-[5rem]">
      <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
        <div className="flex justify-between items-center">
          <h1 className="mb-2 font-bold text-lg">Writing (60 minutes)</h1>
          {expanded1 || expanded2 || expanded3 || expanded4 ? (
            <button
              onClick={collapseAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Collapse All <ChevronUpIcon className="scale-150" />
            </button>
          ) : (
            <button
              onClick={expandAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Expand All <ChevronDownIcon className="scale-150" />
            </button>
          )}
        </div>
        <hr />
        <p className="mt-2">
          <span className="font-semibold">IELTS Academic test: </span>
          Topics are of general interest to, and suitable for, test takers
          entering undergraduate and postgraduate studies or seeking
          professional registration.
        </p>
        <p className="mt-2">
          <span className="font-semibold">IELTS General Training test: </span>
          Topics are of general interest.
        </p>
        <p className="mt-2">There are two tasks:</p>

        <ul className="transition-all ease-in-out duration-300">
          {/* 1. Task 1 (Academic) */}

          <li
            className="mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand1}
          >
            <span className="text-[#3182ce]">1. Task 1 (Academic)</span>
            <span
              className={`transform ${
                expanded1 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color="#3182ce" />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded1 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers may be asked to
              describe facts or figures presented in one or more graphs, charts
              or tables on a related topic; or they may be given a diagram of a
              machine, a device or a process and asked to explain how it works.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You should write in an
              academic or semi-formal/neutral styles and include the most
              important and the most relevant points in the diagram.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers should spend no
              more than 20 minutes on this task. They are asked to write at
              least 150 words and will be penalised if their answer is too
              short.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> While test takers will not be
              penalised for writing more than 150 words, they should remember
              that a longer Task 1 answer may mean that they have less time to
              spend on Task 2, which contributes twice as much to the Writing
              band score.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> Test takers should also note
              that they will be penalised for irrelevance if the response is
              off-topic or is not written as full, connected text (e.g. using
              bullet points in any part of the response, or note form, etc.).
              They will be severely penalised for plagiarism (i.e. copying from
              another source).
            </p>
          </div>

          {/* 2. Task 2 (General training) */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand2}
          >
            <span className="text-[#3182ce]">2. Task 2 (General training)</span>
            <span
              className={`transform ${
                expanded2 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded2 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are presented
              with a situation and required to write a personal response in the
              form of an informal, semi-formal or formal letter of at least 150
              words in the answer booklet provided.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The style of writing that
              test takers use depends who they are asked to write to (i.e. the
              audience) and how well they are supposed to know them. They need
              to write in a style that is appropriate for their audience and
              that will help them to achieve their purpose for writing, e.g.
              writing to a friend (informal) or writing to a manager
              (semi-formal or formal).
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers should spend no
              more than 20 minutes on this task. They are asked to write at
              least 150 words and will be penalised if their answer is too
              short. While test takers will not be penalised for writing more
              than 150 words, they should remember that a longer Task 1 answer
              may mean that they have less time to spend on Task 2, which
              contributes twice as much to the Writing band score.
            </p>

            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> Test takers should also note
              that they will be penalised for irrelevance, if the response is
              off-topic or is not written as full, connected text (e.g. using
              bullet points in any part of the response, or note form, etc.).
              They will be severely penalised for plagiarism (i.e. copying from
              another source).
            </p>
          </div>

          {/* 3. Task 3 (Academic) */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand3}
          >
            <span className="text-[#3182ce]">3. Task 3 (Academic)</span>
            <span
              className={`transform ${
                expanded3 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded3 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are given a topic
              to write about an academic or semi-formal/neutral style. Answers
              should be a discursive consideration of the relevant issues.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers should spend no
              more than 40 minutes on this task. They are asked to write at
              least 250 words and will be penalised if their answer is too
              short. While test takers will not be penalised for writing more
              than 250 words, if they write a very long answer they may not have
              time for checking and correcting at the end and some ideas may not
              be directly relevant to the question.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> Test takers will be penalised
              for irrelevance if the response is off-topic or is not written as
              full, connected text (e.g. using bullet points in any part of the
              response, or note form, etc.). They will be severely penalised for
              plagiarism (i.e. copying from another source).
            </p>
          </div>

          {/* Task 4 (General training) */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand4}
          >
            <span className="text-[#3182ce]">4. Task 4 (General training)</span>
            <span
              className={`transform ${
                expanded4 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded4 ? "max-h-screen" : ""
            }`}
          >
            <hr />
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers write a
              semi-formal/neutral discursive essay of at least 250 words in the
              answer book provided.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Topics are of general
              interest, such as: whether children's leisure activities should be
              educational, why families are not so close as they used to be and
              how they could be brought closer, how environmental problems can
              be solved, who should pay for the care of old people, whether
              smoking should be banned in public places.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are asked to
              write at least 250 words and will be penalised if their answer is
              too short. They should spend no more than 40 minutes on this task.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> Test takers should also note
              that they will be penalised for irrelevance if the response is
              off-topic or is not written as full, connected text (e.g. using
              bullet points in any part of the response, or note form, etc.).
              They will be severely penalised for plagiarism (i.e. copying from
              another source).
            </p>
          </div>
        </ul>
      </section>
    </div>
  );
};

export default Writing;
