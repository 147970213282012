import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useState } from "react";

const Reading = () => {
  // State Variables
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [expanded5, setExpanded5] = useState(false);

  // State Functions
  const expand1 = () => {
    setExpanded1(!expanded1);
  };
  const expand2 = () => {
    setExpanded2(!expanded2);
  };
  const expand3 = () => {
    setExpanded3(!expanded3);
  };
  const expand4 = () => {
    setExpanded4(!expanded4);
  };
  const expand5 = () => {
    setExpanded5(!expanded5);
  };
  const collapseAll = () => {
    setExpanded1(false);
    setExpanded2(false);
    setExpanded3(false);
    setExpanded4(false);
    setExpanded5(false);
  };
  const expandAll = () => {
    setExpanded1(true);
    setExpanded2(true);
    setExpanded3(true);
    setExpanded4(true);
    setExpanded5(true);
  };
  return (
    <div className="flex flex-col items-center justify-center gap-10 mt-4 mb-[5rem]">
      <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
        <div className="flex justify-between items-center">
          <h1 className="mb-2 font-bold text-lg">Reading (29-30 minutes)</h1>
          {expanded1 || expanded2 || expanded3 || expanded4 || expanded5 ? (
            <button
              onClick={collapseAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Collapse All <ChevronUpIcon className="scale-150" />
            </button>
          ) : (
            <button
              onClick={expandAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Expand All <ChevronDownIcon className="scale-150" />
            </button>
          )}
        </div>
        <hr />
        <p className="mt-2">
          This part of test contains five different question types, and tests
          your ability to read academic English.
        </p>
        <ul className="transition-all ease-in-out duration-300">
          <li
            className="mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand1}
          >
            <span className="text-[#3182ce]">
              1. Reading and writing: Fill in the blanks
            </span>
            <span
              className={`transform ${
                expanded1 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color="#3182ce" />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded1 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you need
              to select the most appropriate words from a drop-down list to
              restore the text.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> There is a passage with some
              missing words. Beside each gap, there is a button with a drop-down
              list. Select the option you think best fills the gap.
            </p>
            <p className="mt-2">
              <span className="font-bold">
                Skills assessed: Reading and Writing
              </span>
            </p>
            {/* Youtube video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/HeG2ktd8j_Q?si=Dd_csJOWyIC3ZaVz"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand2}
          >
            <span className="text-[#3182ce]">
              2. Multiple choice: Multiple answers
            </span>
            <span
              className={`transform ${
                expanded2 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded2 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you need
              to read the passage and answer the multiple-choice question.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> You need to select all the
              options that you think are correct from the list of possible
              options.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The options you select are
              highlighted in yellow.
            </p>
            <p className="mt-2 font-bold">Skills accessed: Reading</p>
            {/* Youtube Video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/z8bPPzNOsHo?si=BdPBpdevPYkGNQMC"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand3}
          >
            <span className="text-[#3182ce]">3. Re-order paragraphs</span>
            <span
              className={`transform ${
                expanded3 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded3 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you need
              to restore the original order of the text by selecting text boxes
              and dragging them across the screen.
            </p>
            <p className="mt-2">
              <span className="font-bold">- Tip: </span> Find the topic sentence
              first.
            </p>
            <p className="mt-2 font-bold">Skills assessed: Reading</p>
            {/* Youtube Video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/m6KEzUzo644?si=xyFlkKoppAG8MwFE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand4}
          >
            <span className="text-[#3182ce]">4. Fill in the blanks</span>
            <span
              className={`transform ${
                expanded4 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded4 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you need
              to drag and drop words across the screen to correctly fill in the
              gaps in the text.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> There is a passage with some
              missing words. You have a list of words in the blue box that you
              can use to fill the gaps in the text.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> There are more words than
              gaps so you will not use all the words provided.
            </p>
            <p className="mt-2">
              <span className="font-bold">Skills assessed: Reading</span>
            </p>
            {/* YouTube video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/ozQgFxJNfIA?si=B1P9iqIl984CYvqx"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand5}
          >
            <span className="text-[#3182ce]">
              5. Multiple choice: Single answer
            </span>
            <span
              className={`transform ${
                expanded5 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded5 ? "max-h-screen" : ""
            }`}
          >
            <hr />
            <p className="mt-2">
              <span className="font-bold">-</span> For this item type, you need
              to read the passage and answer the multiple-choice question.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> There are several possible
              options but only one is correct.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The option you select is
              highlighted in yellow.
            </p>
            <p className="mt-2">
              <span className="font-bold">Skills assessed: Reading</span>
            </p>
            {/* YouTube video goes here */}
            <div className="mt-2 mb-6 w-full flex items-center justify-center">
              <div className="max-w-screen-lg mx-auto">
                <iframe
                  className="w-[80vw] h-[170px] md:w-[560px] md:h-[300px] lg:w-[720px] lg:h-[400px] rounded-2xl"
                  src="https://www.youtube.com/embed/-LBv0uLD2mI?si=Lt5RmkUlDqJMzdz_"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </ul>
      </section>
    </div>
  );
};

export default Reading;
