import { useState } from "react";
import Ielts from "./Ielts";
import Pte from "./Pte";
import Tofel from "./Tofel";
import { ChevronRightIcon } from "@chakra-ui/icons";

const Test = () => {
  const [tests, setTests] = useState("");

  const openIelts = () => {
    setTests("ielts");
  };
  const openPte = () => {
    setTests("pte");
  };
  const openTofel = () => {
    setTests("tofel");
  };
  return (
    <>
      <div
        className={`transition-all ease-in-out duration-300 container mx-auto px-8 flex flex-col align-center justify-center max-w-[1080px] ${
          tests === "" ? "h-[75vh]" : "h-[50vh]"
        }`}
      >
        <h1 className="text-2xl text-center font-bold my-8">
          Select English Test Preparation
        </h1>
        <div className="mb-4 flex flex-col large-row items-center justify-center gap-16">
          <button
            className="flex items-center justify-center"
            onClick={openPte}
          >
            <p
              className={`flex items-center justify-between transition-all ease-in-out duration-300 border px-4 py-2 rounded-lg ${
                tests === "pte" ? "bg-[#2ec7bf]" : "bg-[#858585]"
              } text-center text-white mb-2 w-[16rem]`}
            >
              <span>PTE Academic</span>
              <span className="border-white rounded-full border-2 flex items-center justify-center">
                <ChevronRightIcon className="text-2xl" />
              </span>
            </p>
          </button>
          <button
            className="flex items-center justify-center"
            onClick={openIelts}
          >
            <p
              className={`flex items-center justify-between transition-all ease-in-out duration-300 border px-4 py-2 rounded-lg ${
                tests === "ielts" ? "bg-[#d02020]" : "bg-[#858585]"
              } text-center text-white mb-2 w-[16rem]`}
            >
              <span>IELTS</span>
              <span className="border-white rounded-full border-2 flex items-center justify-center">
                <ChevronRightIcon className="text-2xl" />
              </span>
            </p>
          </button>
          <button
            className="flex items-center justify-center"
            onClick={openTofel}
          >
            <p
              className={`flex items-center justify-between transition-all ease-in-out duration-300 border px-4 py-2 rounded-lg ${
                tests === "tofel" ? "bg-[#3b82f6]" : "bg-[#858585]"
              } text-center text-white mb-2 w-[16rem]`}
            >
              <span>TOFEL iBT</span>
              <span className="border-white rounded-full border-2 flex items-center justify-center">
                <ChevronRightIcon className="text-2xl" />
              </span>
            </p>
          </button>
        </div>
      </div>
      {tests === "ielts" ? (
        <Ielts />
      ) : tests === "pte" ? (
        <Pte />
      ) : tests === "tofel" ? (
        <Tofel />
      ) : (
        ""
      )}
    </>
  );
};

export default Test;
