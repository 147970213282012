import { Link } from "react-router-dom";

const Reading = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-10 mt-4 mb-[5rem]">
      <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
        <h1 className="font-bold text-lg">Reading (54-72 minutes)</h1>
        <hr className="mt-1" />
        <p className="mt-2">
          This section includes three or four reading passages, each
          approximately 700 words long, with 10 questions per passage.
        </p>
        <p className="mt-2">
          Reading passages are excerpts from university-level textbooks that
          would be used in introductions to a topic. The passages cover a
          variety of subjects.
        </p>
        <p className="mt-2">Following are the type of questions in Reading:</p>
        <p className="mt-2">
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/reading-factual-information.html"
            target="_blank"
            className=" text-[#1267E9]"
          >
            Reading Factual Information
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/reading-inference-rhetorical-purpose.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Inference and Rhetorical
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/reading-vocabulary.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Reading Vocabulary
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/reading-sentence-simplicification.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Sentence Simplification
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/reading-insert-text-question.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Insert Text Question
          </Link>
        </p>
      </section>
    </div>
  );
};

export default Reading;
