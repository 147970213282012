import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useState } from "react";

const Speaking = () => {
  // State Variables
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);

  // State Functions
  const expand1 = () => {
    setExpanded1(!expanded1);
  };
  const expand2 = () => {
    setExpanded2(!expanded2);
  };
  const expand3 = () => {
    setExpanded3(!expanded3);
  };
  const collapseAll = () => {
    setExpanded1(false);
    setExpanded2(false);
    setExpanded3(false);
  };
  const expandAll = () => {
    setExpanded1(true);
    setExpanded2(true);
    setExpanded3(true);
  };
  return (
    <div className="flex flex-col items-center justify-center gap-10 mt-4 mb-[5rem]">
      <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
        <div className="flex justify-between items-center">
          <h1 className="mb-2 font-bold text-lg">Speaking (11-14 minutes)</h1>
          {expanded1 || expanded2 || expanded3 ? (
            <button
              onClick={collapseAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Collapse All <ChevronUpIcon className="scale-150" />
            </button>
          ) : (
            <button
              onClick={expandAll}
              className="flex items-center justify-center gap-2 px-4 py-2 text-[#3182ce]"
            >
              Expand All <ChevronDownIcon className="scale-150" />
            </button>
          )}
        </div>
        <hr />
        <p className="mt-2">
          The Speaking test consists of an oral interview between the test
          takers' and an examiner. All Speaking tests are recorded.
        </p>
        <p className="mt-2">
          There are 3 parts to the test and each part fulfils a specific
          function in terms of interaction pattern, task input and test takers
          output.
        </p>
        <ul className="transition-all ease-in-out duration-300">
          {/* Part 1: Introduction and interview */}

          <li
            className="mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand1}
          >
            <span className="text-[#3182ce]">
              Part 1: Introduction and interview
            </span>
            <span
              className={`transform ${
                expanded1 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color="#3182ce" />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded1 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> In this part, the examiner
              introduces him/herself and checks the test takers' identity. They
              then ask the test takers general questions on some familiar topics
              such as home, family, work, studies and interests.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> Part 1 lasts for 4-5 minutes.
            </p>
          </div>

          {/* Part 2: Long turn */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand2}
          >
            <span className="text-[#3182ce]">Part 2: Long turn</span>
            <span
              className={`transform ${
                expanded2 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <hr />
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded2 ? "max-h-screen" : ""
            }`}
          >
            <p className="mt-2">
              <span className="font-bold">-</span> The examiner gives the test
              takers a task card which asks the test takers to talk about a
              particular topic, includes points to cover in their talk and
              instructs the test takers to explain one aspect of the topic.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> Test takers are given one
              minute to prepare their talk, and are given a pencil and paper to
              make notes.
            </p>
            <p className="mt-2">
              <span className="font-bold">-</span> The examiner asks the test
              takers to talk for 1 to 2 minutes, stops the test takers after 2
              minutes, and asks one or two questions on the same topic.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> Part 2 lasts 3-4 minutes,
              including the preparation time.
            </p>
          </div>

          {/* Part 3: Discussion */}

          <li
            className="mt-2 mb-1 cursor-pointer flex justify-between items-center"
            onClick={expand3}
          >
            <span className="text-[#3182ce]">Part 3: Discussion</span>
            <span
              className={`transform ${
                expanded3 ? "rotate-180" : "rotate-0"
              } transition-transform`}
            >
              <ChevronDownIcon className="scale-[2]" color={"#3182ce"} />
            </span>
          </li>
          <div
            className={`transition-all ease-in-out delay-0 duration-300 max-h-0 overflow-hidden ${
              expanded3 ? "max-h-screen" : ""
            }`}
          >
            <hr />
            <p className="mt-2">
              <span className="font-bold">-</span> In Part 3, the examiner and
              the test takers discuss issues related to the topic in Part 2 in a
              more general and abstract way and, where appropriate, in greater
              depth.
            </p>
            <p className="mt-2 mb-8">
              <span className="font-bold">-</span> Part 3 lasts 4-5 minutes.
            </p>
          </div>
        </ul>
      </section>
    </div>
  );
};

export default Speaking;
