import { Link } from "react-router-dom";

const Listening = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-10 mt-4 mb-[5rem]">
      <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
        <h1 className="text-lg font-bold">Listening (41-57 minutes)</h1>
        <hr className="mt-1" />
        <p className="mt-2">
          You will hear lectures and conversations in this section. Both use
          campus-based language.
        </p>
        <p className="mt-2">
          There will be 3-4 lectures, some with classroom discussion, each 3-5
          minutes; 6 questions per lecture.
        </p>
        <p className="mt-2">
          There will be 2-3 conversations, each 3 minutes; 5 questions per
          conversation.
        </p>
        <p className="mt-2">
          You can take notes on any audio item throughout the test to help you
          answer questions.
        </p>
        <p className="mt-2">
          Following are the type of questions in Listening:
        </p>
        <p className="mt-2">
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/listening-gist-content-purpose.html"
            target="_blank"
            className=" text-[#1267E9]"
          >
            Gist-Content and Gist-Purpose
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/listening-detail.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Detail
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/listening-function.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Function
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/listening-attitude.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Attitude
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/listening-organization.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Organization
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/listening-connecting-content.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Connecting Content
          </Link>
          <hr className="my-1" />
          <Link
            to="https://www.ets.org/toefl/test-takers/ibt/transcript/listening-inference.html"
            target="_blank"
            className="text-[#1267E9]"
          >
            Inference
          </Link>
        </p>
      </section>
    </div>
  );
};

export default Listening;
